@import "main.css";
.svg-picto-card > svg {
    width: 18%;
    height: 20%;
    margin: 0 auto;
    color: #ff6600;
}
.btnDevis {

position: fixed;

bottom: 20px; right: 0;

font-size: 18px;

padding: 10px 20px;

background: #ff6600;

color: black;

text-decoration: none;

border-radius : 30px 0 0 30px !important;

z-index: 10;

font-family: 'Dosis';

}
.btnDevis:hover {

background: #DF5A02;

color: black;

}
.text-color-seventh {
    color: #000000 !important;
}
.bandeau {
    min-height: 36vh;
    background-color: #ffffff;
}